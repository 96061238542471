import React from "react";
import PropTypes from "prop-types";

export const FloatTeaser = ({ offset = 40, children }) => (
  <div
    className="float-teaser float-teaser-right bg-monday-yellow vertical-offset reveal"
    data-offset={`${offset}%`}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-4">
          <div className="float-teaser-body pl-3 pl-md-5 py-3 py-md-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

FloatTeaser.propTypes = {
  children: PropTypes.node,
  offset: PropTypes.number,
};
