import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "react-intl";

export const ServicesGrid = ({ title, ctaLink, ctaText }) => {
  const intl = useIntl();
  ServicesGrid.propTypes = {
    title: PropTypes.string,
    ctaLink: PropTypes.string,
    ctaText: PropTypes.string,
  };
  return (
    <div className="content-container py-4 pt-md-5 bg-monday-green bg-pattern-cross text-color-white parallax-background">
      <div className="container">
        <div className="row mb-4">
          <h2 className="col-12 mb-3 text-left text-md-center">{title}</h2>
          {ctaLink && (
            <div className="col-12 text-left text-md-center">
              <Link to={ctaLink} className="btn">
                {ctaText}
              </Link>
            </div>
          )}
        </div>
        <div className="row">
          <Service
            title={intl.formatMessage({ id: "service.strategy" })}
            serviceLink={intl.formatMessage({ id: "service.strategy.link" })}
          >
            <FormattedMessage id="service.strategy.text" />
          </Service>
          <Service
            title={intl.formatMessage({ id: "service.cms" })}
            serviceLink={intl.formatMessage({ id: "service.cms.link" })}
          >
            <FormattedMessage id="service.cms.text" />
          </Service>
          <Service
            title={intl.formatMessage({ id: "service.design" })}
            serviceLink={intl.formatMessage({ id: "service.design.link" })}
          >
            <FormattedMessage id="service.design.text" />
          </Service>
          <Service
            title={intl.formatMessage({ id: "service.frontend" })}
            serviceLink={intl.formatMessage({ id: "service.frontend.link" })}
          >
            <FormattedMessage id="service.frontend.text" />
          </Service>
          <Service
            title={intl.formatMessage({ id: "service.backend" })}
            serviceLink={intl.formatMessage({ id: "service.backend.link" })}
          >
            <FormattedMessage id="service.backend.text" />
          </Service>
          <Service
            title={intl.formatMessage({ id: "service.projectmanagement" })}
            serviceLink={intl.formatMessage({
              id: "service.projectmanagement.link",
            })}
          >
            <FormattedMessage id="service.projectmanagement.text" />
          </Service>
        </div>
      </div>
    </div>
  );
};

export const Service = ({ title, serviceLink, children }) => (
  <div className="col-12 col-md-6 col-lg-4 mb-4 d-flex flex-column align-items-start justify-content-between">
    <div className="mb-3">
      <h3 className="h4">{title}</h3>
      <p>{children}</p>
    </div>
    {serviceLink && serviceLink.trim().length !== 0 && (
      <Link to={serviceLink} className="btn">
        {title}
      </Link>
    )}
  </div>
);

Service.propTypes = {
  title: PropTypes.string,
  serviceLink: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object),
};
